import { useState } from "react";
import { ChoseDate } from "../choseDate";
import { ChoseTime } from "../choseTime";

export function Indeterminate({ schedulingData, generateTimesInterval }) {
	// States
	const [currentStep, setCurrentStep] = useState("date");
	const [day, setDay] = useState(null);
	const [avaliableTimes, setAvailableTimes] = useState([]);
	const [loading, setLoading] = useState(true);

	// ==== Return ====
	return (
		<>
			{
				{
					date: (
						<ChoseDate
							setCurrentStep={setCurrentStep}
							setDay={setDay}
							day={day}
							toClose={schedulingData.toClose}
							setSchedulingDate={schedulingData.setSchedulingDate}
							setAvailableTimes={setAvailableTimes}
							setLoading={setLoading}
							generateTimesInterval={generateTimesInterval}
						/>
					),
					time: (
						<ChoseTime
							availableTimes={avaliableTimes}
							schedulingData={schedulingData}
							toClose={schedulingData.toClose}
							loading={loading}
						/>
					),
				}[currentStep]
			}
		</>
	);
}
