import { useEffect, useState } from "react";
import { SmallModal } from "../../../../../../components/smallModal";
import { api } from "../../../../../../services/api";
import moment from "moment";
import { Wrap } from "./style";
import { SpaceLoader } from "../../../../../../components/loaders/spaceLoader";

export function ChoseDate({ cdEmpresa, setCurrentStep, setDate, toClose }) {
	const [isLoading, setIsLoading] = useState(true);
	const [dates, setDates] = useState([]);

	useEffect(() => {
		api
			.get(`/getSchedulingPeriods/${cdEmpresa}`)
			.then(({ data }) => {
				const datesIntervals = data.schedulingPeriods;
				const datesToChose = [];

				for (const datesInterval of datesIntervals) {
					while (
						moment(datesInterval.dtEnd).add(1, "days").format("YYYY-MM-DD") >=
						moment(datesInterval.dtStart).add(1, "days").format("YYYY-MM-DD")
					) {
						datesInterval.dtStart = moment(datesInterval.dtStart)
							.add(1, "days")
							.format("YYYY-MM-DD");
						datesToChose.push(datesInterval.dtStart);
					}
				}

				setDates(datesToChose);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [cdEmpresa]);

	return (
		<SmallModal title="Escolha uma data" toClose={toClose}>
			<div>
				{isLoading ? (
					<>
						<SpaceLoader />
					</>
				) : (
					dates.map((date) => (
						<Wrap key={date}>
							<p>{moment(date).format("DD/MM/YYYY")}</p>
							<button
								type="button"
								onClick={() => {
									setCurrentStep("time");
									setDate(date);
								}}
							>
								Selecionar
							</button>
						</Wrap>
					))
				)}
			</div>
		</SmallModal>
	);
}
