import { Daily } from "./components/daily";
import { Weekly } from "./components/weekly";
import { Indeterminate } from "./components/indeterminate";
import { Period } from "./components/period";

export function SchedulingModal({
	toClose,
	scheduling,
	setSchedulingTime,
	generateTimesInterval,
	setSchedulingDate,
	schedulingDate,
	cdEmpresa,
	setPeriodChose,
}) {
	const schedulingData = {
		toClose,
		scheduling,
		setSchedulingTime,
		generateTimesInterval,
		setSchedulingDate,
		schedulingDate,
		cdEmpresa,
		setPeriodChose,
	};

	// ==== Render ====
	return (
		<>
			{
				{
					daily: (
						<Daily
							schedulingData={schedulingData}
							generateTimesInterval={generateTimesInterval}
						/>
					),
					weekly: (
						<Weekly
							schedulingData={schedulingData}
							generateTimesInterval={generateTimesInterval}
						/>
					),
					indeterminate: (
						<Indeterminate
							schedulingData={schedulingData}
							generateTimesInterval={generateTimesInterval}
						/>
					),
					period: (
						<Period
							cdEmpresa={cdEmpresa}
							toClose={toClose}
							schedulingData={schedulingData}
						/>
					),
				}[scheduling.type]
			}
		</>
	);
}
