import styled from "styled-components";

export const Wrap = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;

  button {
    border: none;
    background-color: var(--brand-500);
    color: #FFF;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: background-color 0.2s;
  }
`;
