import { ChoseMotoboy } from "./components/choseMotoboyDialog";
import { CloseCashRegister } from "./components/closeCashRegister";
import { ManageStores } from "./components/manageStores";
import { OpenCashRegister } from "./components/openCashRegister";
import { Orders } from "./components/orders";
import { PrintOrder } from "./components/printOrder";
import { SchedulingModal } from "./components/schedulingModal";
import { LinksStores } from "./components/linksStores/index";
import { TopBar } from "./components/topBar";
import { OrderManagerProvider } from "./context";

export function OrderManager() {
	return (
		<OrderManagerProvider>
			<PrintOrder />
			<ChoseMotoboy />
			<OpenCashRegister />
			<CloseCashRegister />
			<SchedulingModal />
			<ManageStores />
			<TopBar />
			<Orders />
			<LinksStores />
		</OrderManagerProvider>
	);
}
