import { useEffect, useState } from "react";
import { SmallModal } from "../../../../../../components/smallModal";
import { api } from "../../../../../../services/api";
import { SpaceLoader } from "../../../../../../components/loaders/spaceLoader";
import { Wrap } from "./style";

export function ChoseTime({ date, cdEmpresa, setCurrentStep, handleChose }) {
	const [times, setTimes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		api
			.get("getSchedulesOrdersByDay", {
				params: {
					idBusiness: cdEmpresa,
					currentDate: date,
				},
			})
			.then(({ data }) => {
				setTimes(data.results);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [cdEmpresa, date]);

	return (
		<SmallModal title="Escolha um horário" toClose={() => setCurrentStep("date")}>
			<div>
				{isLoading ? (
					<>
						<SpaceLoader />
					</>
				) : (
					<>
						{!times.length && <p>Não há horários disponíveis</p>}
						{times.map((time, index) => (
							<Wrap key={index}>
								<p>{time.NovoHorario}</p>
								{time.hasLimit && <p>disponíveis: {time.pedidosDisponiveis}</p>}
								<button
									type="button"
									disabled={time.pedidosDisponiveis === 0 && time.hasLimit}
									onClick={() => {
										setCurrentStep("time");
										handleChose(time.NovoHorario);
									}}
								>
									Selecionar
								</button>
							</Wrap>
						))}
					</>
				)}
			</div>
		</SmallModal>
	);
}
