import { useEffect, useState } from "react";
import { ChoseDay } from "../choseDay";
import { ChoseTime } from "../choseTime";
import moment from "moment";

export function Weekly({ schedulingData, generateTimesInterval }) {
	// States
	const [currentStep, setCurrentStep] = useState("day");
	const [day, setDay] = useState(null);
	const [avaliableTimes, setAvailableTimes] = useState([]);
	const [loading, setLoading] = useState(true);

	// Effects
	useEffect(() => {
		(async () => {
			if (day) {
				let date;

				// Get next date available on day recieved
				for (let index = 0; index <= 7; index++) {
					if (
						moment()
							.add(index, "days")
							.format("LLLL")
							.split(",")[0]
							.toLocaleLowerCase() === day
					) {
						date = moment().add(index, "days").format("YYYY-MM-DD");
						break;
					}
				}

				const times = await generateTimesInterval({
					date,
				});

				setAvailableTimes(times);
				setLoading(false);
			}
		})();
	}, [day, generateTimesInterval]);

	// ==== Return ====
	return (
		<>
			{
				{
					day: (
						<ChoseDay
							setCurrentStep={setCurrentStep}
							availableDays={schedulingData.scheduling.schedulingTimes}
							day={day}
							setDay={setDay}
							toClose={schedulingData.toClose}
							setSchedulingDate={schedulingData.setSchedulingDate}
						/>
					),
					time: (
						<ChoseTime
							availableTimes={avaliableTimes}
							schedulingData={schedulingData}
							toClose={schedulingData.toClose}
							setCurrentStep={setCurrentStep}
							day={day}
							loading={loading}
						/>
					),
				}[currentStep]
			}
		</>
	);
}
