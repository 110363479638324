import { useState } from "react";
import { ChoseDate } from "./choseDate";
import { ChoseTime } from "./choseTime";

export function Period({ cdEmpresa, toClose, schedulingData }) {
	const [currentStep, setCurrentStep] = useState("date");
	const [date, setDate] = useState();

	function handleChose(newtime) {
		schedulingData.setSchedulingDate(date);
		schedulingData.setSchedulingTime(newtime);
		schedulingData.setPeriodChose(true);
		toClose();
	}

	return (
		<>
			{
				{
					date: (
						<ChoseDate
							setCurrentStep={setCurrentStep}
							setDate={setDate}
							cdEmpresa={cdEmpresa}
							toClose={toClose}
						/>
					),
					time: (
						<ChoseTime
							setCurrentStep={setCurrentStep}
							date={date}
							cdEmpresa={cdEmpresa}
							handleChose={handleChose}
						/>
					),
				}[currentStep]
			}
		</>
	);
}
