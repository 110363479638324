import moment from "moment";
import { SmallModal } from "../../../../../../components/smallModal";
import { Advice, List } from "./style";
import { useEffect } from "react";
import { useState } from "react";
import { SpaceLoader } from "../../../../../../components/loaders/spaceLoader";
import { useMemo } from "react";

export function Daily({ schedulingData, generateTimesInterval }) {
	const [avaliableTimes, setAvaliableTimes] = useState([]);
	const [avaliableTimesIsLoading, setAvaliableTimesIsLoading] = useState(true);

	const timesToday = useMemo(
		() =>
			schedulingData.scheduling.schedulingTimes.filter(
				(time) =>
					time.weekDay ===
					moment().utcOffset(0).format("LLLL").split(",")[0].toLocaleLowerCase(),
			),
		[schedulingData],
	);

	function handleChoseTime({ time }) {
		schedulingData.setSchedulingTime(time.NovoHorario);
		schedulingData.toClose();
	}

	useEffect(() => {
		(async () => {
			const times = await generateTimesInterval({
				date: timesToday.startTime,
			});

			setAvaliableTimes(times);
			setAvaliableTimesIsLoading(false);
		})();
	}, [timesToday, generateTimesInterval]);

	return (
		<SmallModal title="Agendamento de pedido" toClose={schedulingData.toClose}>
			<Advice>
				Loja fechada no momento <br />
				Selecione um dia para agendar seu pedido
			</Advice>
			{avaliableTimesIsLoading ? (
				<SpaceLoader />
			) : (
				<List>
					{avaliableTimes.length === 0 && !avaliableTimesIsLoading && (
						<li className="expired">Não há horários disponíveis</li>
					)}
					{avaliableTimes?.map((time, index) => {
						return (
							<li
								key={index}
								className={time.pedidosDisponiveis <= 0 ? "expired" : ""}
								onClick={() => {
									if (time.pedidosDisponiveis <= 0) return;
									handleChoseTime({ time });
								}}
								onKeyDown={() => {
									if (time.pedidosDisponiveis <= 0) return;
									handleChoseTime({ time });
								}}
								disabled={time.pedidosDisponiveis <= 0}
							>
								{time.NovoHorario} - {time.pedidosDisponiveis} disponíveis
								<button type="button">
									{time.pedidosDisponiveis <= 0 ? "Indisponível" : "Selecionar"}
								</button>
							</li>
						);
					})}
				</List>
			)}
		</SmallModal>
	);
}
