export function fractionalNumberFormatted(num, fracionado) {
	if (Number.isInteger(num) && !fracionado) {
		return num;
	}

	return new Intl.NumberFormat("pt-BR", {
		minimumFractionDigits: 3,
		maximumFractionDigits: 3,
	}).format(num);
}

export function numberExportExcel(num) {
	return Number(num).toFixed(2).replace(".", ",");
}

export function formatPercentage(num) {
	// Divide the value by 100
	let percentage = (Number(num) / 100).toFixed(2);

	// Replace the decimal point with a comma
	percentage = percentage.replace(".", ",");

	// Append the percentage sign
	return `${percentage}%`;
}

export function padZeroes(num, totalLength) {
	try {
		return String(num).padStart(totalLength, "0");
	} catch (error) {
		return num;
	}
}
