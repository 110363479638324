import React, { useEffect, useState } from "react";
import { useOrderManagerContext } from "../../context";
import { Container, Modal, Title, Button, QrCodeContent, QrCodeActions } from "./style";
import { Select } from "../../../../components/ui/base-components";
import QRCode from "react-qr-code";
import QRCodeLink from "qrcode";
import { FaDownload, FaCopy, FaLink } from "react-icons/fa";

export function LinksStores() {
	const { showLinksStores, setShowLinksStores, companies } = useOrderManagerContext();
	const [businessMessage, setBusinessMessage] = useState("");
	const [qrcodeContent, setQrcodeContent] = useState("");
	const [qrcodeLink, setQrcodeLink] = useState("");

	const handleGenerateQrCode = (linkURL) => {
		QRCodeLink.toDataURL(
			linkURL,
			{
				width: 300,
				margin: 3,
			},
			(err, url) => {
				setQrcodeLink(url);
			},
		);
	};

	const handleCopyLink = () => {
		navigator.clipboard.writeText(qrcodeContent);
	};

	const handleCopyWhatsappMessage = () => {
		navigator.clipboard.writeText(businessMessage);
	};

	useEffect(() => {
		const company = companies[0];
		let link = company?.nomeEmpresaApi
			? `https://inoveclube.com/menu/${company?.nomeEmpresaApi}/`
			: "";
		let whatsappMessage = company?.textoLinkWhatsapp ? company?.textoLinkWhatsapp : "";
		setQrcodeContent(link);
		handleGenerateQrCode(link);
		setBusinessMessage(whatsappMessage);
	}, [companies]);

	const handleChangeBusiness = (event) => {
		const company = companies[event.target.value];
		let link = company?.nomeEmpresaApi
			? `https://www.inoveclube.com/menu/${company?.nomeEmpresaApi}/`
			: "";
		let whatsappMessage = company?.textoLinkWhatsapp ? company?.textoLinkWhatsapp : "";
		handleGenerateQrCode(link);
		setQrcodeContent(link);
		setBusinessMessage(whatsappMessage);
	};

	return showLinksStores ? (
		<Container>
			<Modal className="scroll">
				<Title>Links</Title>
				<Select onChange={handleChangeBusiness}>
					{companies.map((item, index) => (
						<option key={index} value={index}>
							{item.dsEmpresa}
						</option>
					))}
				</Select>

				<QrCodeContent>
					<QRCode value={qrcodeContent} size={200} className="qrcodeImage" />
					<QrCodeActions className="qrcodeActions">
						<a href={qrcodeLink} download={"InoveClubeQrCode.png"}>
							<FaDownload size={24} />
						</a>
						<FaCopy size={24} onClick={handleCopyWhatsappMessage} />
						<FaLink size={24} onClick={handleCopyLink} />
					</QrCodeActions>
				</QrCodeContent>

				<Button
					className="large"
					onClick={() => {
						setShowLinksStores(false);
					}}
				>
					Fechar
				</Button>
			</Modal>
		</Container>
	) : null;
}
