import { List } from "./style";
import { SmallModal } from "../../../../../../components/smallModal";
import moment from "moment";
import { SpaceLoader } from "../../../../../../components/loaders/spaceLoader";
moment.locale("en");

export function ChoseTime({
	availableTimes,
	toClose,
	schedulingData,
	day,
	loading,
	setCurrentStep,
}) {
	function handleChoseTime({ time }) {
		schedulingData.setSchedulingTime(time);
		if (schedulingData.schedulingDate === "change") {
			if (
				day === moment().utcOffset(0).format("LLLL").split(",")[0].toLocaleLowerCase()
			) {
				if (time > moment().format("HH:mm")) {
					schedulingData.setSchedulingDate(moment().format("YYYY-MM-DD"));
				} else {
					schedulingData.setSchedulingDate(moment().add(7, "days").format("YYYY-MM-DD"));
				}
			} else {
				for (let index = 1; index <= 7; index++) {
					if (
						moment()
							.add(index, "days")
							.format("LLLL")
							.split(",")[0]
							.toLocaleLowerCase() === day
					) {
						schedulingData.setSchedulingDate(
							moment().add(index, "days").format("YYYY-MM-DD"),
						);
						break;
					}
				}
			}
		}
		schedulingData.toClose();
	}

	return (
		<SmallModal
			title="Agendamento de pedido"
			toClose={() => {
				if (schedulingData.schedulingDate !== "change") {
					toClose();
				} else {
					setCurrentStep("day");
				}
			}}
		>
			{loading && <SpaceLoader />}
			<List>
				{!loading && availableTimes.length === 0 ? (
					<li>Não há horários disponíveis</li>
				) : (
					availableTimes?.map((time, index) => (
						<li
							key={index}
							className={time.pedidosDisponiveis <= 0 ? "expired" : ""}
							onClick={() => {
								if (time.pedidosDisponiveis <= 0) return;
								handleChoseTime({ time: time.NovoHorario });
							}}
							onKeyDown={() => {
								if (time.pedidosDisponiveis <= 0) return;
								handleChoseTime({ time: time.NovoHorario });
							}}
							disabled={time.pedidosDisponiveis <= 0 && time.hasLimite}
						>
							{time.NovoHorario}
							{time.hasLimite && <>- {time.pedidosDisponiveis} disponíveis</>}
							<button type="button">
								{time.pedidosDisponiveis <= 0 && time.hasLimite
									? "Indisponível"
									: "Selecionar"}
							</button>
						</li>
					))
				)}
			</List>
		</SmallModal>
	);
}
