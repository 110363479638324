import { Button } from "../../../../../../components/Button";
import { SmallModal } from "../../../../../../components/smallModal";
import { Container } from "./style";

export function ChoseDate({
	setCurrentStep,
	day,
	setDay,
	toClose,
	setSchedulingDate,
	setAvailableTimes,
	setLoading,
	generateTimesInterval,
}) {
	async function handleNext() {
		if (day) {
			const times = await generateTimesInterval({
				date: day,
			});

			setAvailableTimes(times);
			setLoading(false);
		}
	}

	return (
		<SmallModal title="Agendamento de pedido" toClose={toClose}>
			<Container>
				<input
					type="date"
					name="date"
					id="date"
					value={day}
					onChange={(e) => {
						if (e.target.value.split("-")[0].length > 4) return;
						setDay(e.target.value);
						setSchedulingDate(e.target.value);
					}}
				/>
				<Button
					className="button"
					disabled={!day}
					onClick={() => {
						setCurrentStep("time");
						handleNext();
					}}
				>
					Próximo
				</Button>
			</Container>
		</SmallModal>
	);
}
